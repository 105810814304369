<template>
  <div class="app-container user-container">
    <el-form :inline="true" :model="listQuery" ref="queryForm">
      <el-form-item label="登录邮箱：">
        <el-input v-model="listQuery.email" placeholder="输入登录邮箱"></el-input>
      </el-form-item>
      <el-form-item label="昵称：">
        <el-input v-model="listQuery.nickname" placeholder="输入账号昵称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">搜索</el-button>
        <el-button type="default" @click="onReset">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="list"
      v-loading="loading"
      border
      fit
      hightlight-current-row
    >
      <el-table-column :resizable='false' label="账户ID" width="200" prop="uuid" align="center" />
      <el-table-column :resizable='false' label="登录邮箱" prop="email" />
      <el-table-column :resizable='false' label="昵称" prop="nickname" />
      <el-table-column :resizable='false' label="注册时间" width="200" align="center" prop="created_time">
        <template slot-scope="scope">
          <span>{{$parseTime(scope.row.created_time, '{y}-{m}-{d} {h}:{i}:{s}')}}</span>
        </template>
      </el-table-column>
    </el-table>

      <!-- 分页 -->
      <el-pagination
        :current-page="listQuery.page"
        :page-size="listQuery.page_size"
        :total="total"
        style="padding: 32px 0;"
        layout="total, prev, pager, next, jumper"
        @current-change="fetchData"/>
  </div>
</template>

<script>
import api from '@/api/customer'

const listQuery = {
  page_size: 20,
  page: 1,
}
export default {
  data() {
    return {
      listQuery: {
        ...listQuery
      },
      total: 0,
      list: [],
      loading: false,
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    onReset() {
      this.listQuery = { ...listQuery }
      this.fetchData(1)
    },
    fetchData(page = 1) {
      this.listQuery.page = page
      this.loading = true
      api.getList(this.listQuery)
      .then(res => {
        this.total = +res.total
        this.list = res.list || []
        this.loading = false
      })
    },
  }
}
</script>

<style lang='less' scoped>
@dark_gray:#889aa4;
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 16px;
    color: @dark_gray;
    cursor: pointer;
    user-select: none;
  }
</style>
